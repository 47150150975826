import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Dimmer, Icon, Loader, Segment, Table } from 'semantic-ui-react'

// contexts
import { useSessionContext } from '../../../contexts/SessionContext'

// utils
import { isDarkTheme } from '../../../utils/theme'

export default function TemplatesTable({ data, loading }) {
	const { theme } = useSessionContext()
	const { t } = useTranslation()

	return (
		<Segment inverted={isDarkTheme(theme)} style={{ marginTop: '50px' }}>
			<Dimmer active={loading} inverted={!isDarkTheme(theme)}>
				<Loader size="large">{t('app.loading')}</Loader>
			</Dimmer>

			<Table size="large" striped inverted={isDarkTheme(theme)}>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>
							{t('template.template')}
						</Table.HeaderCell>
						<Table.HeaderCell>
							{t('template.date')}
						</Table.HeaderCell>
						<Table.HeaderCell>
							{t('template.is_active')}
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{data.results.map((template) => (
						<Table.Row key={template.id}>
							<Table.Cell>
								<Link to={`templates/${template.id}`}>
									{template.name}
								</Link>
							</Table.Cell>

							<Table.Cell>{template.created_at}</Table.Cell>

							<Table.Cell>
								{template.is_active ? (
									<Icon
										color="green"
										name="checkmark"
										size="large"
									/>
								) : (
									<Icon
										color="red"
										name="close"
										size="large"
									/>
								)}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</Segment>
	)
}
