import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Dropdown, Grid, Icon, Input, Menu } from 'semantic-ui-react'

// custom components
import ExamsFilesForm from './ExamsFilesForm'
import ExamsTable from './ExamsTable'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import Menubar from '../../components/Menubar'
import SolutionTitle from '../../components/SolutionTitle'

// contexts
import { useSessionContext } from '../../contexts/SessionContext'

// hooks
import useAuth from '../../hooks/useAuth'
import usePageTitle from '../../hooks/usePageTitle'

// utils
import HttpClient from '../../services/HttpClient'
import { jobStatus } from '../../utils/jobStatus'
import { isDarkTheme } from '../../utils/theme'

// styles
import './styles.scss'

const CHECK_INTERVAL = 30000

export default function Exams() {
	const { pathname } = useLocation()
	const serviceAlias = pathname.split('/').pop()

	const { session, theme } = useSessionContext()
	const { t } = useTranslation()
	usePageTitle(t('app.exams'), 'exams')
	const inputRef = useRef(null)

	const [checkExams, setCheckExams] = useState(false)
	const [data, setData] = useState({
		count: 1,
		page_size: 0,
		next: '',
		previous: '',
		results: [],
	})
	const [filter, setFilter] = useState({
		page: 1,
		search: '',
		status: '',
	})
	const [loading, setLoading] = useState(true)
	const [loadingTable, setLoadingTable] = useState(true)
	const [serviceData, setServiceData] = useState({
		remaining_exams: 0,
	})

	useAuth()

	function handleFilter(key, value) {
		setFilter({ ...filter, page: 1, [key]: value })
	}

	function handleSubmit(event) {
		event.preventDefault()
		const { value } = inputRef.current.inputRef.current
		handleFilter('search', value)
	}

	async function loadExams(showLoading=true) {
		setLoadingTable(showLoading)
		const { data: responseData } = await HttpClient.get(
			`${serviceAlias}/exams?job_status=${filter.status}&search=${filter.search}&page=${filter.page}`
		)
		// console.log(response.data)
		setData(responseData)
		setLoading(false)
		setLoadingTable(false)
	}

	async function checkService() {
		const { data: responseData } = await HttpClient.get(`${serviceAlias}/exams/check_service`)
		setServiceData(responseData)
		// console.log(serviceData)
	}

	useEffect(async () => {
		if (session.auth) {
			if (loading) {
				const { data: responseData } = await HttpClient.get(`${serviceAlias}/exams/check_exams`)
				setCheckExams(!responseData.finished)
			}
			loadExams()
			checkService()
		}
		return null
		// eslint-disable-next-line
	}, [filter, session.auth])

	useEffect(() => {
		if (checkExams) {
			const showLoading = false
			const interval = setInterval(async () => {
				const { data: responseData } = await HttpClient.get(`${serviceAlias}/exams/check_exams`)
				if (responseData.finished) {
					setCheckExams(false)
					loadExams(showLoading)
				}
			}, CHECK_INTERVAL)
			return () => clearInterval(interval)
		}
		return null
		// eslint-disable-next-line
	}, [checkExams])

	return (
		<Menubar>
			{ loading ? (
				<Loading />
			) : (
				<Container style={{ flex: '1', marginTop: '100px' }}>
					<SolutionTitle
						title={t(`delfos.${serviceAlias}`)}
						to="/solutions"
					/>

					<Grid stackable columns="equal">
						<Grid.Column width={12}>
							<Menu
								fluid
								widths={2}
								inverted={isDarkTheme(theme)}
							>
								<Dropdown
									clearable
									item
									options={jobStatus.map(item => (
										{...item, text: t(`exam.${item.key}`)}
									))}
									onChange={(event, { value }) =>
										handleFilter('status', value)
									}
									placeholder={t('exam.status')}
									selectOnBlur={false}
								/>

								<Menu.Item style={{ padding: '0.5rem' }}>
									<form
										onSubmit={handleSubmit}
										style={{ width: '100%' }}
									>
										<Input
											icon={
												<Icon
													circular
													inverted
													link
													name="search"
													type="submit"
													onClick={event =>handleSubmit(event)}
												/>
											}
											inverted
											placeholder={t('exam.search')}
											ref={inputRef}
											size="large"
											style={{ width: '95%' }}
										/>
									</form>
								</Menu.Item>
							</Menu>

							<ExamsTable
								data={data}
								loading={loadingTable}
								serviceAlias={serviceAlias}
								setPage={page => setFilter({ ...filter, page })}
							/>
						</Grid.Column>

						<Grid.Column>
							<ExamsFilesForm
								resetFilter={() => setFilter({
									page: 1, search: '', status: '',
								})}
								serviceAlias={serviceAlias}
								serviceData={serviceData}
								setCheckExams={setCheckExams}
							/>
						</Grid.Column>
					</Grid>
				</Container>
			)}

			<Footer />
		</Menubar>
	)
}
