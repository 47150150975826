import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useSessionContext } from '../contexts/SessionContext'

// utils
import HttpClient from '../services/HttpClient'
import {
	cleanLocationState,
	fromRedirect,
	REDIRECT_STATE,
} from '../utils/navigation'
import { notifyError, serverErrorMessage } from '../utils/notify'

const defaultSetPreloader = () => {}

export async function handleSession(
	session,
	token,
	setSession,
	history,
	setPreloader
) {
	if (session) {
		console.log(`isAuthenticated: ${session.auth}`)
		setSession({
			auth: session.auth,
			csrf: token,
			isStaff: session.isStaff,
		})
		if (!session.auth) {
			history.push('/login', REDIRECT_STATE)
		}
		setPreloader(false)
	} else {
		notifyError(serverErrorMessage)
	}
}

export default function useAuth(setPreloader = defaultSetPreloader) {
	const { session, setSession } = useSessionContext()
	const history = useHistory()
	const { location } = history

	useEffect(() => {
		const { csrf } = session
		// console.log(location)
		if (!fromRedirect(location) || !csrf) {
			HttpClient.getSession()
				.then(({ data, token }) => {
					data.then((sessionData) => {
						// console.log(sessionData, token)
						handleSession(
							sessionData,
							token,
							setSession,
							history,
							setPreloader
						)
					})
				})
				.catch((error) => console.log(error))
		} else {
			setPreloader(false)
		}
		cleanLocationState(history)
		// eslint-disable-next-line
	}, [])
}
