import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Grid, Icon } from 'semantic-ui-react'

// custom componenets
import DefaultBackground from '../../components/DefaultBackground'

// custom hooks
import usePageTitle from '../../hooks/usePageTitle'

// styles
import './styles.scss'

export default function NotFound({ showLink = true }) {
	const { t } = useTranslation()
	usePageTitle(t('not_found.page_title'), 'not-found')

	return (
		<DefaultBackground>
			<Grid textAlign="center" verticalAlign="middle">
				<Grid.Column>
					<Icon name="home" size="massive" />

					<h1>{t('not_found.oops')}</h1>

					{showLink && (
						<Link to="/">
							<h3>{t('not_found.back')}</h3>
						</Link>
					)}
				</Grid.Column>
			</Grid>
		</DefaultBackground>
	)
}
