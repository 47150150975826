import React from 'react'
import { Link } from 'react-router-dom'
import { Header, Icon } from 'semantic-ui-react'

// contexts
import { useSessionContext } from '../../contexts/SessionContext'

// utils
import { isDarkTheme } from '../../utils/theme'

// styles
import './styles.scss'

export default function SolutionTitle({ title, to }) {
	const { theme } = useSessionContext()

	return (
		<div className="solution-title">
			<Link to={to} className="ui circular icon button">
				<Icon name="arrow left" />
			</Link>

			<Header as="h2" inverted={isDarkTheme(theme)}>
				{title}
			</Header>
		</div>
	)
}
