import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import cx from 'classnames'

// contexts
import { useSessionContext } from '../../contexts/SessionContext'

// custom components
import DefaultBackground from '../../components/DefaultBackground'
import LoginFooter from '../../components/LoginFooter'

// custom hooks
import usePageTitle from '../../hooks/usePageTitle'

// utils
import HttpClient from '../../services/HttpClient'
import {
	notifyError,
	notifySuccess,
	notifyWarning,
	serverErrorMessage,
} from '../../utils/notify'

export default function SignIn({ setChallenge, setForm }) {
	const { t } = useTranslation()
	usePageTitle(t('login.login'), 'login')
	const history = useHistory()
	const { session } = useSessionContext()
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [isPassword, setIsPassword] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	async function handleSubmit(e) {
		e.preventDefault()
		setButtonLoading(true)
		const formData = {
			username,
			password,
		}
		const response = await HttpClient.post('login/', formData, session.csrf)
		if (response) {
			//   console.log(response)
			if (response.data) {
				if (!response.data.challenge) {
					history.push('/solutions')
					notifySuccess(response.message)
				} else {
					setForm('FirstAccess')
					setChallenge({ ...response.data, username })
				}
			} else {
				notifyWarning(response.message)
			}
		} else {
			notifyError(serverErrorMessage)
		}
		setButtonLoading(false)
	}

	function validateForm() {
		return username && password
	}

	return (
		<DefaultBackground>
			<Grid textAlign="center" verticalAlign="middle">
				<Grid.Column>
					<Header as="h1" textAlign="center" inverted>
						<Icon name="sign in" size="massive" />
						{t('login.login')}
					</Header>

					<Form size="large" onSubmit={handleSubmit}>
						<Segment>
							<Form.Input
								fluid
								icon="user"
								iconPosition="left"
								placeholder={t('login.username')}
								type="text"
								onChange={(e, { value }) =>
									setUsername(value.trim())
								}
								value={username}
							/>

							<Form.Input
								fluid
								iconPosition="left"
								name="password"
								placeholder={t('login.password')}
								type={isPassword ? 'password' : 'text'}
								onChange={(e, { value }) =>
									setPassword(value.trim())
								}
								value={password}
							>
								<Icon name="lock" />
								<input />

								<Icon
									className="link"
									name={cx({
										eye: true,
										slash: isPassword,
									})}
									onClick={() => setIsPassword(!isPassword)}
								/>
							</Form.Input>

							<Button
								className="blue-harpia"
								fluid
								loading={buttonLoading}
								size="large"
								disabled={!validateForm() || buttonLoading}
							>
								{t('login.login')}
							</Button>

							<br />
							<br />

							<Link to="/login/forgot/">
								<Button
									inverted
									size="small"
									className="no-border"
								>
									{t('login.forgot')}
								</Button>
							</Link>
						</Segment>
					</Form>
				</Grid.Column>
			</Grid>

			<LoginFooter />
		</DefaultBackground>
	)
}
