import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'

// custom components
import FirstAccess from './FirstAccess'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import SignIn from './SignIn'
import NotFound from '../NotFound'
import { LoadingBackground } from '../../components/Loading'

// hooks
import useProfile from '../../hooks/useProfile'

// styles
import './styles.scss'

export default function Login() {
	const [challenge, setChallenge] = useState({})
	const [form, setForm] = useState('SignIn')
	const [loading, setLoading] = useState(true)

	useProfile(setLoading)

	const forms = {
		SignIn: <SignIn setChallenge={setChallenge} setForm={setForm} />,
		FirstAccess: <FirstAccess challenge={challenge} setForm={setForm} />,
	}

	if (loading) {
		return <LoadingBackground />
	}

	return (
		<Switch>
			<Route exact path="/login">
				{forms[form]}
			</Route>

			<Route exact path="/login/forgot/">
				<ForgotPassword />
			</Route>

			<Route exact path="/login/reset">
				<ResetPassword />
			</Route>

			<Route component={NotFound} />
		</Switch>
	)
}
