import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Header, Icon, Radio } from 'semantic-ui-react'
import ResponsiveHeader from 'dna-responsive-nav'
import cx from 'classnames'

// contexts
import { useSessionContext } from '../../contexts/SessionContext'

// utils
import HttpClient from '../../services/HttpClient'
import { REDIRECT_STATE } from '../../utils/navigation'
import { notifyWarning } from '../../utils/notify'
import { themes } from '../../utils/theme'

// styles
import 'dna-responsive-nav/dist/dna-rn.css'
import './styles.scss'

export default function Menubar({ children, appendLinks = [] }) {
	const history = useHistory()
	const { t } = useTranslation()
	const { theme, changeTheme, session } = useSessionContext()
	const [buttonLoading, setButtonLoading] = useState(false)

	function logout() {
		setButtonLoading(true)
		HttpClient.get('logout/')
			.then((response) => {
				if (response.success) {
					history.push('/login/')
					notifyWarning(response.message)
				}
			})
			.catch((error) => {
				console.log(error)
				setButtonLoading(false)
			})
	}

	const links = (
		<>
			<ul>
				<li key="solutions">
					<Link
						to={{
							pathname: '/solutions',
							state: REDIRECT_STATE,
						}}
					>
						<Icon name="sitemap" />

						<span className="md-hidden">{t('app.solutions')}</span>
					</Link>
				</li>

				{appendLinks.map((link) => (
					<li key={link.route}>
						<Link
							to={{
								pathname: link.route,
								state: REDIRECT_STATE,
							}}
						>
							<Icon name={link.icon} />

							<span className="md-hidden">
								{t(`menubar.${link.label}`)}
							</span>
						</Link>
					</li>
				))}

				{session.isStaff && (
					<li key="staff-area">
						<a
							href={process.env.REACT_APP_STAFF_AREA}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Icon name="user" />
							<span className="md-hidden">
								{t('menubar.staff')}
							</span>
						</a>
					</li>
				)}
			</ul>

			<div className="switch-container">
				<Header as="h4" inverted style={{ margin: 0, marginRight: 10 }}>
					{themes[theme].icon}
				</Header>

				<Radio
					className="dark-mode-switcher"
					onChange={changeTheme}
					defaultChecked={theme === 'dark-theme'}
					toggle
				/>
			</div>

			<Button
				animated="vertical"
				loading={buttonLoading}
				disabled={buttonLoading}
				onClick={logout}
			>
				<Button.Content visible>{t('menubar.log_out')}</Button.Content>

				<Button.Content hidden>
					<Icon fitted name="log out" />
				</Button.Content>
			</Button>
		</>
	)

	return (
		<>
			<ResponsiveHeader
				links={links}
				logo="/images/delfos-white-horizontal.svg"
			/>

			<div
				className={cx({
					'full-screen': true,
					[theme]: true,
				})}
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{children}
			</div>
		</>
	)
}
