import React from 'react'
import { useTranslation } from 'react-i18next'
import { Header } from 'semantic-ui-react'

// styles
import './styles.scss'

export default function LoginFooter() {
	const { t } = useTranslation()

	return (
		<Header as="h5" className="LoginFooter" textAlign="center" inverted>
			{t('footer.made_by')}{' '}
			<a
				href="https://www.harpiahealth.com/"
				target="_blank"
				rel="noreferrer"
			>
				Harpia Health Solutions
			</a>
		</Header>
	)
}
