import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Card, Grid, Header, Image } from 'semantic-ui-react'

// custom components
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import Menubar from '../../components/Menubar'

// contexts
import { useSessionContext } from '../../contexts/SessionContext'

// hooks
import useAuth from '../../hooks/useAuth'
import usePageTitle from '../../hooks/usePageTitle'

// utils
import HttpClient from '../../services/HttpClient'
import { REDIRECT_STATE } from '../../utils/navigation'

// styles
import './styles.scss'

export default function SelectSolution() {
	const { session } = useSessionContext()
	const { t } = useTranslation()
	usePageTitle(t('app.solutions'), 'select-solution')
	const [loading, setLoading] = useState(true)
	const [solutions, setSolutions] = useState([])

	useAuth()

	async function loadDelfosServices() {
		const { data } = await HttpClient.get('user_services')
		// console.log(data)
		setSolutions(data)
		setLoading(false)
	}

	useEffect(() => {
		if (session.auth) {
			loadDelfosServices()
		}
		// eslint-disable-next-line
	}, [session.auth])

	return (
		<Menubar>
			{loading ? (
				<Loading />
			) : (
				<Container
					className="main-content"
					style={{ flex: '1', marginTop: '100px' }}
				>
					{ !solutions.length ? (
						<div style={{width: '100%'}}>
							<Header as="h2" textAlign="center">
								{t('app.no_solutions')}
							</Header>
						</div>
					) : (
						<div className="cards-container">
							{solutions.map(({ service }) => (
								<Grid.Column
									key={service.alias}
									className="card-solution"
								>
									<Link
										className="ui card"
										to={{
											pathname: `/solutions/${service.alias}`,
											state: REDIRECT_STATE,
										}}
									>
										<Image
											src={`/images/solutions/${service.alias}-resize.jpg`}
											wrapped
											ui={false}
										/>

										<Card.Content>
											<Card.Header>
												{service.name}
											</Card.Header>

											<Card.Description>
												{service.description}
											</Card.Description>
										</Card.Content>
									</Link>
								</Grid.Column>
							))}
						</div>
					)}
				</Container>
			)}

			<Footer />
		</Menubar>
	)
}
