import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Container,
	Grid,
	Header,
	Icon,
	Image,
	List,
	Menu,
	Segment,
} from 'semantic-ui-react'

import usePageTitle from '../../hooks/usePageTitle'

// styles
import './styles.scss'

export default function Welcome() {
	const { t } = useTranslation()
	usePageTitle(t('welcome.page_title'), 'home-page')

	return (
		<>
			<Segment
				inverted
				textAlign="center"
				style={{ minHeight: '100vh', padding: '1em 0em' }}
				vertical
			>
				<div className="default-background" />

				<div className="futuristic-loading" />

				<Menu inverted pointing secondary size="large">
					<Container>
						<Link to="/" className="active item">
							Delfos
						</Link>

						<Menu.Item
							as="a"
							href="https://www.harpiahealth.com"
							target="_blank"
							rel="noreferrer"
						>
							Harpia
						</Menu.Item>

						<Menu.Item
							as="a"
							href="https://www.harpiahealth.com/blog"
							target="_blank"
							rel="noreferrer"
						>
							Blog
						</Menu.Item>

						<Menu.Item
							as="a"
							href="https://www.harpiahealth.com/#fale-conosco"
							target="_blank"
							rel="noreferrer"
						>
							Fale conosco
						</Menu.Item>

						<Menu.Item position="right">
							<Link
								to="/login"
								className="ui inverted button"
								role="button"
							>
								Log in
							</Link>
						</Menu.Item>
					</Container>
				</Menu>

				<Container
					text
					style={{
						position: 'inherit',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						height: '90vh'
					}}
				>
					<Header
						as="h1"
						content="DELFOS SOLUTIONS"
						inverted
						style={{
							fontSize: '4em',
							fontWeight: 'normal',
						}}
					/>

					<Header
						as="h2"
						content="Aqui você pode ver os resultados dos exames processados"
						inverted
						style={{
							fontSize: '1.7em',
							fontWeight: 'normal',
							margin: '1.5em 0',
						}}
					/>

					<Link
						to="/login"
						className="ui primary button huge"
						role="button"
					>
						Começar
						<Icon name="right arrow" />
					</Link>
				</Container>
			</Segment>

			<Segment vertical style={{ padding: '8em 0em' }}>
				<Grid container stackable verticalAlign="middle">
					<Grid.Row>
						<Grid.Column width={8}>
							<Header as="h3" style={{ fontSize: '2em' }}>
								Inteligência Artificial apoiando o diagnóstico
							</Header>

							<p style={{ fontSize: '1.33em' }}>
								Desenvolvemos soluções de IA para auxiliar
								médicos a tomarem decisões de maneira mais
								cuidadosa e assertiva
							</p>

							<Header as="h3" style={{ fontSize: '2em' }}>
								Nossa missão
							</Header>

							<p style={{ fontSize: '1.33em' }}>
								Transformar a saúde através de tecnologia,
								melhorando a qualidade de vida de médicos e
								pacientes.
							</p>
						</Grid.Column>

						<Grid.Column floated="right" width={6}>
							<Image
								size="large"
								src="/images/solutions/how-it-works.svg"
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>

			<Segment style={{ padding: '0em' }} vertical>
				<Grid celled="internally" columns="equal" stackable>
					<Grid.Row textAlign="center">
						<Grid.Column
							style={{ paddingBottom: '5em', paddingTop: '5em' }}
						>
							<Header as="h3" style={{ fontSize: '2em' }}>
								&quot;O principal na sua tela&quot;
							</Header>

							<p style={{ fontSize: '1.33em' }}>
								Resumo das indicações visuais do estudo
								<br />
								disponibilizada na série do exame
							</p>
						</Grid.Column>

						<Grid.Column
							style={{ paddingBottom: '5em', paddingTop: '5em' }}
						>
							<Header as="h3" style={{ fontSize: '2em' }}>
								&quot;Resultado prático e elegante&quot;
							</Header>

							<p style={{ fontSize: '1.33em' }}>
								Relatório de indicações computacionais
								<br />
								com as análises e achados identificados
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>

			<Segment style={{ padding: '8em 0em' }} vertical>
				<Container text textAlign="center">
					<Header as="h3" style={{ fontSize: '2em' }}>
						&quot;Mais comodidade e agilidade&quot;
					</Header>

					<p style={{ fontSize: '1.33em' }}>
						Preenchimento automático do laudário agilizando a
						emissão do laudo
					</p>
				</Container>
			</Segment>

			<Segment inverted vertical style={{ padding: '5em 0em' }}>
				<Container>
					<Grid divided inverted stackable>
						<Grid.Row>
							<Grid.Column width={5}>
								<Header inverted as="h4" content="Quem somos" />

								<List link inverted>
									<a
										className="item"
										href="http://www.harpiahealth.com"
										target="_blank"
										rel="noreferrer"
									>
										Somos uma empresa de desenvolvimento de
										métodos computacionais para o auxílio ao
										diagnóstico de exames de imagens médicas
									</a>
								</List>
							</Grid.Column>

							<Grid.Column width={4}>
								<Header inverted as="h4" content="Soluções" />

								<List link inverted>
									<List.Item>Delfos Mamografia</List.Item>
									<List.Item>
										Delfos Raio-X de Tórax
									</List.Item>
									<List.Item>
										Delfos Densitometria Óssea
									</List.Item>
								</List>
							</Grid.Column>

							<Grid.Column width={7}>
								<Header as="h4" inverted>
									Contato
								</Header>

								<List link inverted>
									<List.Item
										as="a"
										href="https://www.harpiahealth.com/#fale-conosco"
										target="_blank"
										rel="noreferrer"
									>
										<Icon
											name="mail"
											style={{ marginRight: '10px' }}
										/>
										Fale conosco
									</List.Item>

									<List.Item
										as="a"
										href="tel:+55 12 982973227"
										target="_blank"
										title="Deseja ligar?"
										rel="noreferrer"
									>
										<Icon
											name="phone"
											style={{ marginRight: '10px' }}
										/>
										+55 12 3976 7705
									</List.Item>

									<List.Item
										as="a"
										href="https://www.google.com/maps/place/Harpia+Health+Solutions+-+Parque+Tecnol%C3%B3gico,+Estrada+Dr.+Altino+Bondensan,+500+-+Eug%C3%AAnio+de+Melo,+S%C3%A3o+Jos%C3%A9+dos+Campos+-+SP,+12247-016/@-23.1575051,-45.7911729,15z/data=!4m6!3m5!1s0x94cc4d7973d4db77:0xde4699065db972a0!8m2!3d-23.1575051!4d-45.7911729!16s%2Fg%2F11j0r_6q85"
										target="_blank"
										title="Onde estamos"
										rel="noreferrer"
										style={{ display: 'flex' }}
									>
										<Icon
											name="map marker alternate"
											style={{ marginRight: '10px' }}
										/>
										<p>
											Parque Tecnológico, Estrada Dr.
											Altino Bondesan, 500 <br />
											Nexus, Sala 8 <br />
											Brasil - São José dos Campos/SP -
											CEP 12247-016
										</p>
									</List.Item>
								</List>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</Segment>
		</>
	)
}
