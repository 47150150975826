import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useSessionContext } from '../contexts/SessionContext'

// utils
import HttpClient from '../services/HttpClient'
import { fromRedirect, REDIRECT_STATE } from '../utils/navigation'
import { serverErrorMessage, notifyError } from '../utils/notify'

function handleSession(session, token, setSession, history, setLoading) {
	if (session) {
		console.log(`isAuthenticated: ${session.auth}`)
		setSession({
			auth: session.auth,
			csrf: token,
			isStaff: session.isStaff,
		})

		if (session.auth) {
			history.push('/solutions', REDIRECT_STATE)
		}
		setLoading(false)
	} else {
		notifyError(serverErrorMessage)
	}
}

export default function useProfile(setLoading) {
	const { session, setSession } = useSessionContext()
	const history = useHistory()
	const routeParams = useLocation()

	useEffect(() => {
		const { csrf } = session
		if (!fromRedirect(routeParams) || !csrf) {
			HttpClient.getSession()
				.then(({ data, token }) => {
					data.then((sessionData) => {
						handleSession(
							sessionData,
							token,
							setSession,
							history,
							setLoading
						)
					})
				})
				.catch((error) => console.log(error))
		} else {
			setLoading(false)
		}
		// eslint-disable-next-line
	}, [])
}
