export const jobStatus = [
	{ key: 'all', text: 'All', value: '' },
	{ key: 'processing', text: 'Processing', value: 'processing' },
	{ key: 'finished', text: 'Finished', value: 'finished' },
	{ key: 'error', text: 'Error', value: 'error' },
	{ key: 'removed', text: 'Removed', value: 'removed' },
]

export const jobStatusColor = {
	processing: 'blue',
	finished: 'green',
	error: 'red',
	removed: 'brown',
}