import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PanelGroup from 'react-panelgroup'
import { ImageOverlay, MapContainer, ZoomControl } from 'react-leaflet'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { Button, Icon } from 'semantic-ui-react'
import { CRS } from 'leaflet'
import 'leaflet-rotate'

// custom components
import Loading from '../../components/Loading'

// hooks
import useAuth from '../../hooks/useAuth'
import usePageTitle from '../../hooks/usePageTitle'

// contexts
import { useSessionContext } from '../../contexts/SessionContext'

// utils
import { calculateZoom, copyToClipboard, downloadImage } from './methods'
import HttpClient from '../../services/HttpClient'
import { splitExamID } from '../../utils/examID'
import { isDarkTheme } from '../../utils/theme'

// styles
import 'leaflet/dist/leaflet.css'
import './styles.scss'

export default function DisplayExam() {
	const { serviceAlias, examId } = useParams()

	const { t } = useTranslation()
	usePageTitle(`${t('exam.exam')} ${splitExamID(examId)}`, 'view-exam')
	const { session, theme } = useSessionContext()

	const [links, setLinks] = useState(null)
	const [imageWidth, setImageWidth] = useState(null)
	const [imageHeight, setImageHeight] = useState(null)
	const [maskHTML, setMaskHTML] = useState('')

	useAuth()

	useEffect(() => {
		async function loadExam() {
			const { data } = await HttpClient.get(
				`${serviceAlias}/exams/${examId}/links`
			)

			const image = new Image()
			image.setAttribute('crossOrigin', window.location.hostname)
			image.src = data.mosaic
			image.onload = () => {
				setImageWidth(image.width)
				setImageHeight(image.height)
				setLinks(data)
			}

			if (data.mask) {
				fetch(data.mask).then((maskData) => {
					maskData.text().then((text) => {
						setMaskHTML(text)
					})
				})
			} else {
				setMaskHTML(t('exam.report_not_found'))
			}
		}

		if (session.auth) {
			return loadExam()
		}

		return null
		// eslint-disable-next-line
	}, [examId, session.auth])

	if (!links) {
		return <Loading full />
	}

	return (
		<PanelGroup
			direction="row"
			borderColor="grey"
			spacing={3}
			panelWidths={[
				{
					size: window.innerWidth * 0.7,
					minSize: 100,
					resize: 'dynamic',
				},
			]}
		>
			<div style={{ width: '100%', height: '100vh' }}>
				<MapContainer
					className={cx({
						dark: isDarkTheme(theme),
					})}
					center={[imageHeight * 0.5, imageWidth * 0.5]}
					crs={CRS.Simple}
					doubleClickZoom
					dragging
					keyboard={false}
					maxZoom={3}
					minZoom={-3}
					rotate
					scrollWheelZoom
					shiftKeyRotate
					touchRotate
					style={{ width: '100%', height: '100%' }}
					zoom={calculateZoom(imageHeight)}
					zoomAnimation={false}
					zoomControl={false}
					zoomSnap={0.001}
				>
					<ZoomControl position="bottomright" />

					<div className="leaflet-top leaflet-right">
						<div className="leaflet-bar leaflet-control">
							<button
								type="button"
								className="leaflet-custom-button"
								title="Download"
								onClick={() =>
									downloadImage(links.mosaic, examId)
								}
							>
								<Icon name="download" />
							</button>
						</div>
					</div>

					<ImageOverlay
						url={links.mosaic}
						bounds={[
							[0, 0],
							[imageHeight, imageWidth],
						]}
						opacity={1}
						zIndex={10}
					/>
				</MapContainer>
			</div>

			<PanelGroup direction="column" borderColor="grey" spacing={3}>
				{links.report ? (
					<object
						alt={t('exam.exam_pdf')}
						aria-label={t('exam.exam_pdf')}
						data={links.report}
						width="100%"
					/>
				) : null}

				<div
					className={cx({
						examMask: true,
						dark: isDarkTheme(theme),
					})}
				>
					<Button
						icon
						size="mini"
						title={t('exam.copy_to_clipboard')}
						onClick={() => copyToClipboard(maskHTML)}
					>
						<Icon name="copy" />
					</Button>

					<div dangerouslySetInnerHTML={{ __html: maskHTML }} />
				</div>
			</PanelGroup>
		</PanelGroup>
	)
}
