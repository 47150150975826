import React, { Suspense } from 'react'
import Routes from './routes'
import { Toaster } from 'react-hot-toast'

// contexts
import { SessionProvider } from './contexts/SessionContext'

// styles
import 'semantic-ui-css/semantic.min.css'

function App() {
	return (
		<Suspense fallback="loading">
			<SessionProvider>
				<Toaster position="top-center" reverseOrder={false} />

				<Routes />
			</SessionProvider>
		</Suspense>
	)
}

export default App
