export function splitExamID(examID) {
	const shortIDindex = 2
	const pattern = /(\w+)-(\w+)-(\w+)/
	const matches = pattern.exec(examID)

	if (matches) {
		return matches[shortIDindex]
	}
	return examID
}

export default splitExamID
