import React from 'react'
import { Grid, Loader } from 'semantic-ui-react'
import { useSessionContext } from '../../contexts/SessionContext'

// utils
import { isDarkTheme } from '../../utils/theme'

// styles
import styles from './Loading.module.scss'

function CustomLoader() {
	const { theme } = useSessionContext()

	return (
		<Grid
			className={`${styles.LoadingContainer} ${theme}`}
			textAlign="center"
			verticalAlign="middle"
		>
			<Grid.Column style={{ maxWidth: 650 }}>
				<Loader
					active
					inline="centered"
					size="massive"
					inverted={isDarkTheme(theme)}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default function Loading({ full }) {
	if (full) {
		return (
			<div style={{ width: '100%', height: '100vh' }}>
				<Loading />
			</div>
		)
	}

	return <CustomLoader />
}

export function LoadingBackground() {
	return (
		<Grid
			className={`${styles.LoadingBackground}`}
			textAlign="center"
			verticalAlign="middle"
		>
			<Grid.Column style={{ maxWidth: 650 }}>
				<Loader active inline="centered" size="massive" />
			</Grid.Column>
		</Grid>
	)
}
