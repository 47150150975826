import { notifyError } from './notify'

export function validatePassword(password, confPassword) {
	const errorList = []
	if (password.length < 8 || password.length > 100)
		errorList.push('A senha deve conter de 8 a 99 caracteres')
	if (!/(?=.*[a-z])/.test(password))
		errorList.push('A senha deve conter um caractere minúsculo')
	if (!/(?=.*[A-Z])/.test(password))
		errorList.push('A senha deve conter um caractere maiúsculo')
	if (!/(?=.*\d)/.test(password))
		errorList.push('A senha deve conter um número')
	if (!/(?=.*[-+_!@#$%^&*.,?])/.test(password))
		errorList.push(
			'A senha deve conter um caractere especial -+_!@#$%^&*.,?'
		)
	if (!(password === confPassword))
		errorList.push('A senhas informadas não coincidem')

	if (errorList.length > 0) {
		errorList.forEach((error) => notifyError(error))
		return false
	}

	return true
}

export function validateUsername(username) {
	const errorList = []
	if (username.length < 4 || username.length > 128)
		errorList.push('O nome de usuário deve conter de 4 a 128 caracteres')
	if (!/(^\S+$)/.test(username))
		errorList.push('O nome de usuário não pode conter espaços em branco')

	if (errorList.length > 0) {
		errorList.forEach((error) => notifyError(error))
		return false
	}

	return true
}
