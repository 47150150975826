import React from 'react'
import { useTranslation } from 'react-i18next'

// styles
import './styles.scss'

export default function Footer() {
	const { t } = useTranslation()

	return (
		<footer className="default-footer">
			{t('footer.made_by')}{' '}
			<a
				href="https://www.harpiahealth.com/"
				target="_blank"
				rel="noreferrer"
			>
				Harpia Health Solutions
			</a>
		</footer>
	)
}
