import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react'

// custom components
import DefaultBackground from '../../components/DefaultBackground'
import LoginFooter from '../../components/LoginFooter'

// custom hooks
import usePageTitle from '../../hooks/usePageTitle'
import { useSessionContext } from '../../contexts/SessionContext'

// utils
import HttpClient from '../../services/HttpClient'
import {
	notifyError,
	notifySuccess,
	notifyWarning,
	serverErrorMessage,
} from '../../utils/notify'

export default function ForgotPassword() {
	const { t } = useTranslation()
	usePageTitle(t('login.forgot'), 'login')
	const history = useHistory()
	const { session } = useSessionContext()
	const [email, setEmail] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)

	async function handleSubmit(e) {
		e.preventDefault()
		setButtonLoading(true)
		const formData = {
			email,
			url: window.location.href,
			path: 'login/reset/',
		}
		const response = await HttpClient.post(
			'forgot/',
			formData,
			session.csrf
		)
		if (response) {
			if (response.data) {
				history.push('/login')
				notifySuccess(response.message)
			} else {
				notifyWarning(response.message)
			}
		} else {
			notifyError(serverErrorMessage)
		}
		setButtonLoading(false)
	}

	function validateForm() {
		return email.length > 0
	}

	return (
		<DefaultBackground>
			<Grid textAlign="center" verticalAlign="middle">
				<Grid.Column>
					<Header as="h1" textAlign="center" inverted>
						{t('login.forgot')}
					</Header>

					<Form size="large" onSubmit={handleSubmit}>
						<Segment>
							<Form.Input
								fluid
								icon="mail"
								iconPosition="left"
								name="email"
								placeholder="Email"
								type="email"
								onChange={(e, { value }) =>
									setEmail(value.trim())
								}
								value={email}
							/>

							<Button
								className="blue-harpia"
								fluid
								size="large"
								loading={buttonLoading}
								disabled={!validateForm() || buttonLoading}
							>
								{t('login.recover')}
							</Button>

							<br />
							<br />

							<Link to="/login/">
								<Button
									inverted
									size="small"
									className="no-border"
								>
									{t('login.remember')}
								</Button>
							</Link>
						</Segment>
					</Form>
				</Grid.Column>
			</Grid>

			<LoginFooter />
		</DefaultBackground>
	)
}
