import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// translate
import './i18n'

// styles
import './App.scss'
import './assets/styles/theme.scss'

ReactDOM.render(<App />, document.getElementById('root'))
