import React from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'

// pages
import DensitometryRoutes from './views/Densitometry/routes'
import DisplayExam from './views/DisplayExam'
import Exams from './views/Exams'
import Login from './views/Login'
import NotFound from './views/NotFound'
import SelectSolution from './views/SelectSolution'
import Welcome from './views/Welcome'

// custom components
import ScrollToTop from './components/ScrollToTop'

function SolutionsRoutes() {
	return (
		<Switch>
			<Route exact path="/solutions" component={SelectSolution} />

			<Route path="/solutions/do" component={DensitometryRoutes} />

			<Route exact path="/solutions/mammo" component={Exams} />

			<Route exact path="/solutions/cxr" component={Exams} />

			<Route
				exact
				path="/solutions/:serviceAlias/:examId"
				component={DisplayExam}
			/>

			<Route component={NotFound} />
		</Switch>
	)
}

function Routes() {
	return (
		<BrowserRouter>
			<ScrollToTop />

			<Switch>
				<Route exact path="/" component={Welcome} />
				<Route path="/login" component={Login} />
				<Route path="/solutions" component={SolutionsRoutes} />
				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	)
}

export default Routes
