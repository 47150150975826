import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import cx from 'classnames'

// custom components
import DefaultBackground from '../../components/DefaultBackground'
import LoginFooter from '../../components/LoginFooter'

// custom hooks
import usePageTitle from '../../hooks/usePageTitle'
import { useSessionContext } from '../../contexts/SessionContext'

// utils
import HttpClient from '../../services/HttpClient'
import {
	notifyError,
	notifySuccess,
	notifyWarning,
	serverErrorMessage,
} from '../../utils/notify'
import { validatePassword } from '../../utils/validation'

export default function FirstAccess({ challenge, setForm }) {
	const { t } = useTranslation()
	usePageTitle(t('login.first'), 'login')
	const history = useHistory()
	const { session } = useSessionContext()
	const [password, setPassword] = useState('')
	const [confPassword, setConfPassword] = useState('')
	const [isPassword, setIsPassword] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	async function handleSubmit(e) {
		e.preventDefault()
		setButtonLoading(true)
		const formData = { ...challenge, password }

		if (validatePassword(password, confPassword)) {
			const response = await HttpClient.post(
				'login/?first=true',
				formData,
				session.csrf
			)
			if (response) {
				if (response.data) {
					//   console.log(response)
					history.push('/solutions')
					notifySuccess(response.message)
				} else {
					history.push('/login')
					setForm('SignIn')
					notifyWarning(response.message)
				}
			} else {
				notifyError(serverErrorMessage)
			}
		}
		setButtonLoading(false)
	}

	function validateForm() {
		return password && confPassword
	}

	return (
		<DefaultBackground>
			<Grid textAlign="center" verticalAlign="middle">
				<Grid.Column>
					<Header as="h2" textAlign="center" inverted>
						{t('login.first_access')}
					</Header>

					<Form size="large" onSubmit={handleSubmit}>
						<Segment>
							<Form.Input
								fluid
								iconPosition="left"
								name="password"
								placeholder={t('login.password')}
								type={isPassword ? 'password' : 'text'}
								onChange={(e, { value }) =>
									setPassword(value.trim())
								}
								value={password}
							>
								<Icon name="lock" />
								<input />
								<Icon
									className="link"
									name={cx({
										eye: true,
										slash: isPassword,
									})}
									onClick={() => setIsPassword(!isPassword)}
								/>
							</Form.Input>

							<Form.Input
								fluid
								iconPosition="left"
								name="confPassword"
								placeholder={t('login.confirm_password')}
								type={isPassword ? 'password' : 'text'}
								onChange={(e, { value }) =>
									setConfPassword(value.trim())
								}
								value={confPassword}
							>
								<Icon name="lock" />
								<input />
								<Icon
									className="link"
									name={cx({
										eye: true,
										slash: isPassword,
									})}
									onClick={() => setIsPassword(!isPassword)}
								/>
							</Form.Input>

							<Button
								className="blue-harpia"
								fluid
								loading={buttonLoading}
								size="large"
								disabled={!validateForm() || buttonLoading}
							>
								{t('login.login')}
							</Button>
						</Segment>
					</Form>
				</Grid.Column>
			</Grid>

			<LoginFooter />
		</DefaultBackground>
	)
}
