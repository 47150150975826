import React, { createContext, useContext, useMemo, useState } from 'react'
import { toggleTheme, getTheme } from '../utils/theme'

export const SessionContext = createContext()

export function SessionProvider({ children }) {
	const [theme, setTheme] = useState(getTheme())
	const [session, setSession] = useState({
		auth: false,
		csrf: false,
		isStaff: false,
	})

	function changeTheme() {
		setTheme(toggleTheme())
	}

	const memoizedProvider = useMemo(
		() => (
			<SessionContext.Provider
				value={{
					theme,
					changeTheme,
					session,
					setSession,
				}}
			>
				{children}
			</SessionContext.Provider>
		),
		[session, setSession, theme]
	)

	return memoizedProvider
}

export function useSessionContext() {
	return useContext(SessionContext)
}
