import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Button,
	Container,
	Dropdown,
	Grid,
	Icon,
	Input,
	Menu,
	Message,
} from 'semantic-ui-react'

// custom components
import ExamsTable from './ExamsTable'
import Loading from '../../../components/Loading'
import SolutionTitle from '../../../components/SolutionTitle'

// contexts
import { useSessionContext } from '../../../contexts/SessionContext'

// hooks
import useAuth from '../../../hooks/useAuth'
import usePageTitle from '../../../hooks/usePageTitle'

// utils
import HttpClient from '../../../services/HttpClient'
import { notifyWarning } from '../../../utils/notify'
import { isDarkTheme } from '../../../utils/theme'

// styles
import './styles.scss'
import { jobStatus } from '../../../utils/jobStatus'

const CHECK_INTERVAL = 30000

export default function Exams() {
	const { session, theme } = useSessionContext()
	const history = useHistory()
	const { t } = useTranslation()
	usePageTitle(t('app.exams'), 'exams')
	const inputRef = useRef(null)

	const [buttonLoading, setButtonLoading] = useState(false)
	const [checkExams, setCheckExams] = useState(false)
	const [data, setData] = useState({
		count: 0,
		page_size: 0,
		next: '',
		previous: '',
		results: [],
	})
	const [filter, setFilter] = useState({
		page: 1,
		search: '',
		status: '',
	})
	const [loading, setLoading] = useState(true)
	const [loadingTable, setLoadingTable] = useState(true)
	const [serviceData, setServiceData] = useState({
		remaining_exams: 0,
	})

	useAuth()

	function handleFilter(key, value) {
		setFilter({ ...filter, page: 1, [key]: value })
	}

	function handleSubmit(event) {
		event.preventDefault()
		const { value } = inputRef.current.inputRef.current
		handleFilter('search', value)
	}

	async function newExam() {
		const postData = {}
		if (serviceData.remaining_exams) {
			setButtonLoading(true)
			const { data: newExamData } = await HttpClient.post(
				'do/exams/',
				postData,
				session.csrf
			)
			history.push(`do/exams/${newExamData.id}`)
		} else {
			notifyWarning(t('notification.warning_upload'))
		}
	}

	async function checkService() {
		const { data: responseData } = await HttpClient.get('do/exams/check_service')
		setServiceData(responseData)
		// console.log(responseData)
	}

	async function loadExams(showLoading=true) {
		setLoadingTable(showLoading)
		const { data: responseData } = await HttpClient.get(
			`do/exams?job_status=${filter.status}&search=${filter.search}&page=${filter.page}`
		)
		setData(responseData)
		setLoading(false)
		setLoadingTable(false)
	}

	useEffect(async () => {
		if (session.auth) {
			if (loading) {
				const { data: responseData } = await HttpClient.get('do/exams/check_exams')
				setCheckExams(!responseData.finished)
			}
			loadExams()
			checkService()
		}
		// eslint-disable-next-line
	}, [filter, session.auth])

	useEffect(() => {
		if (checkExams) {
			const showLoading = false
			const interval = setInterval(async () => {
				const { data: responseData } = await HttpClient.get('do/exams/check_exams')
				if (responseData.finished) {
					setCheckExams(false)
					loadExams(showLoading)
				}
			}, CHECK_INTERVAL)
			return () => clearInterval(interval)
		}
		return null
		// eslint-disable-next-line
	}, [checkExams])

	if (loading) {
		return <Loading />
	}

	return (
		<Container style={{ minHeight: '70vh', marginTop: '100px' }}>
			<Grid stackable columns="equal">
				<Grid.Column width={16}>
					<SolutionTitle
						title={t('delfos.densitometry')}
						to="/solutions"
					/>

					{serviceData.remaining_exams <= 0 && (
						<Message
							warning
							header={t('common.warning')}
							list={[t('exam.cant_submit')]}
						/>
					)}

					<Menu fluid widths={3} inverted={isDarkTheme(theme)}>
						<Menu.Item style={{ padding: 0 }}>
							<Button
								fluid
								loading={buttonLoading}
								primary
								onClick={() => newExam()}
								disabled={
									serviceData.remaining_exams === 0 ||
									buttonLoading
								}
								style={{
									borderRadius: 0,
									height: '100%',
								}}
							>
								<Icon name="plus" />
								{t('exam.new_exam')}
							</Button>
						</Menu.Item>

						<Dropdown
							clearable
							item
							options={jobStatus.map(item => (
								{...item, text: t(`exam.${item.key}`)}
							))}
							onChange={(event, { value }) =>
								handleFilter('status', value)
							}
							placeholder={t('exam.status')}
							selectOnBlur={false}
						/>

						<Menu.Item style={{ padding: '0.5rem' }}>
							<form
								onSubmit={handleSubmit}
								style={{ width: '100%' }}
							>
								<Input
									icon={
										<Icon
											circular
											inverted
											link
											name="search"
											onClick={event => handleSubmit(event)}
										/>
									}
									inverted
									placeholder={t('exam.search')}
									ref={inputRef}
									size="large"
									style={{ width: '95%' }}
								/>
							</form>
						</Menu.Item>
					</Menu>

					<ExamsTable
						data={data}
						loading={loadingTable}
						setPage={page => setFilter({ ...filter, page })}
					/>
				</Grid.Column>
			</Grid>
		</Container>
	)
}
