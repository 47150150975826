import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Icon, Message } from 'semantic-ui-react'
import cx from 'classnames'

// contexts
import { useSessionContext } from '../../contexts/SessionContext'

// utils
import { filesFormData, handleSelectFiles } from './methods'
import HttpClient from '../../services/HttpClient'
import { notifySuccess, notifyWarning } from '../../utils/notify'
import { isDarkTheme } from '../../utils/theme'

export default function ExamsFilesForm({
	resetFilter,
	serviceAlias,
	serviceData,
	setCheckExams,
}) {
	const { session, theme } = useSessionContext()
	const { t } = useTranslation()
	const inputFileRef = useRef(null)
	const [loading, setLoading] = useState('')
	const [studyName, setStudyName] = useState('')
	const [filesUpload, setFilesUpload] = useState([])

	async function handleSubmit(event) {
		event.preventDefault()

		if (serviceData.remaining_exams > 0) {
			setLoading('loading')
			const formData = filesFormData(filesUpload)
			formData.append('study_name', studyName)
			const { data, message } = await HttpClient.postFormData(
				`${serviceAlias}/exams`,
				formData,
				session.csrf
			)

			try {
				if (data) {
					notifySuccess(message)
					setFilesUpload([])
					setStudyName('')
					resetFilter()
					setCheckExams(true)
				} else {
					notifyWarning(message)
				}
			} catch (err) {
				notifyWarning(t('notification.error_upload'))
			}
			setLoading('')
		} else {
			notifyWarning(t('notification.warning_upload'))
		}
	}

	function validateForm() {
		return filesUpload.length && studyName && serviceData.remaining_exams
	}

	if (serviceData.remaining_exams) {
		return (
			<>
				<Message
					attached
					content={t('exam.send_dicom')}
					header={t('exam.process_exam')}
					color={isDarkTheme(theme) ? 'black' : 'grey'}
				/>

				<Form
					className={cx({
						'ui form attached fluid segment': true,
						loading,
						inverted: isDarkTheme(theme),
					})}
					onSubmit={event => handleSubmit(event)}
				>
					<Form.Input
						fluid
						label={t('exam.study_name')}
						placeholder={t('exam.study_name')}
						onChange={(event, data) => setStudyName(data.value)}
						value={studyName}
						required
					/>

					<Form.Field>
						<label htmlFor="files-input">{t('exam.files')}</label>

						<div className="folder-input-container">
							<input
								accept=".dcm"
								className="files-input"
								id="files-input"
								multiple
								ref={inputFileRef}
								type="file"
								name="files"
								onChange={(event) =>
									handleSelectFiles(
										event.target.files,
										setFilesUpload,
										inputFileRef
									)
								}
							/>

							<a
								href="#files-input"
								className="ui left action input fluid"
								onClick={event => {
									event.preventDefault()
									inputFileRef.current.click()}
								}
							>
								<button
									type="button"
									className="ui icon button"
								>
									<Icon name="file" />
								</button>

								<div className="selected-files">
									{filesUpload.length
										? `${filesUpload.length} ${t('exam.selected_files')}`
										: t('exam.no_files')}
								</div>
							</a>
						</div>
					</Form.Field>

					<Form.Button fluid primary disabled={!validateForm()}>
						{t('exam.submit')}
					</Form.Button>

					<Form.Button
						fluid
						type="button"
						onClick={() => {
							handleSelectFiles([], setFilesUpload, inputFileRef)
							setStudyName('')
						}}
					>
						{t('exam.cancel')}
					</Form.Button>
				</Form>
			</>
		)
	}

	return (
		<Message
			warning
			header={t('common.warning')}
			list={[t('exam.cant_submit')]}
		/>
	)
}
