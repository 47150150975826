import { useEffect } from 'react'
import { title } from '../utils/page'

function usePageTitle(pageName, pageClassName) {
	useEffect(() => {
		title(document, pageName)
		document.body.classList.remove(...document.body.classList)
		document.body.classList.add(pageClassName)
		// eslint-disable-next-line
	}, [])
}

export default usePageTitle
