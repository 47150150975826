import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Container, Form, Grid, Segment } from 'semantic-ui-react'

// ckeditor
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

// custom components
import Loading from '../../../components/Loading'

// contexts
import { useSessionContext } from '../../../contexts/SessionContext'

// hooks
import useAuth from '../../../hooks/useAuth'
import usePageTitle from '../../../hooks/usePageTitle'

// utils
import HttpClient from '../../../services/HttpClient'
import { notifySuccess, notifyWarning } from '../../../utils/notify'
import { isDarkTheme } from '../../../utils/theme'

// styles
import './styles.scss'
import SolutionTitle from '../../../components/SolutionTitle'

const editorTags = [
	'{{ spine.region_start }}',
	'{{ spine.region_end }}',
	'{{ spine.bmd }}',
	'{{ spine.bmd_relative }}',
	'{{ spine.bmd_std }}',
	'{{ femur.side }}',
	'{{ femur.region }}',
	'{{ femur.bmd }}',
	'{{ femur.bmd_relative }}',
	'{{ femur.bmd_std }}',
	// '{{ radius_33.side }}',
	// '{{ radius_33.bmd }}',
	// '{{ radius_33.bmd_relative }}',
	// '{{ radius_33.bmd_std }}',
	'{{ diagnosis }}',
]

export default function TemplatePage() {
	const { template_id } = useParams()
	const { session, theme } = useSessionContext()
	const { t } = useTranslation()
	usePageTitle(`${t('template.template')} ${template_id}`, 'template')

	const [template, setTemplate] = useState({})
	const [loading, setLoading] = useState(true)

	useAuth()

	function handleChange(key, value) {
		setTemplate((current) => ({ ...current, [key]: value }))
	}

	async function handleSubmit(event) {
		event.preventDefault()
		const { data, message } = await HttpClient.patch(
			`do/templates/${template_id}`,
			template,
			session.csrf
		)
		try {
			if (data) {
				notifySuccess(message)
			} else {
				notifyWarning(message)
			}
		} catch (err) {
			console.log(err)
			notifyWarning(t('notification.error_upload'))
		}
		setLoading('')
	}

	async function loadTemplate() {
		const { data } = await HttpClient.get(`do/templates/${template_id}`)
		setTemplate(data)
		setLoading(false)
	}

	useEffect(() => {
		if (session.auth) {
			loadTemplate()
		}
		// eslint-disable-next-line
	}, [session.auth])

	if (loading) {
		return <Loading />
	}

	return (
		<Container style={{ marginTop: '100px' }}>
			<SolutionTitle
				title={t('template.customize')}
				to="/solutions/do/templates"
			/>

			<Grid columns="equal" reversed="mobile" stackable>
				<Grid.Column width={16}>
					<Segment
						color={isDarkTheme(theme) ? 'black' : 'blue'}
						padded="very"
						inverted={isDarkTheme(theme)}
					>
						<Form
							onSubmit={(event) => handleSubmit(event)}
							inverted={isDarkTheme(theme)}
						>
							<Form.Group
								style={{
									alignItems: 'flex-end',
									justifyContent: 'space-between',
									rowGap: '20px',
								}}
							>
								<Form.Input
									defaultValue={template.name}
									disabled={!template.is_editable}
									label={t('template.name')}
									onChange={(event, { value }) =>
										handleChange('name', value)
									}
									placeholder={t('template.name')}
									width={10}
								/>

								<Form.Radio
									className="switcher"
									disabled={!template.is_editable}
									defaultChecked={template.is_active}
									label={t('template.is_active')}
									onChange={(event, { checked }) =>
										handleChange('is_active', checked)
									}
									toggle
								/>
							</Form.Group>

							<br />
							<br />

							<Form.Field>
								<label htmlFor="ckeditorInput">
									{t('template.editor')}

									<CKEditor
										id="ckeditorInput"
										editor={Editor}
										disabled={!template.is_editable}
										data={template.template}
										// onReady={() => {
										// 	console.log('Editor is ready to use!')
										// }}
										onChange={(event, editor) => {
											const data = editor.getData()
											// console.log(data)
											handleChange('template', data)
										}}
										config={{
											mention: {
												feeds: [
													{
														marker: '{',
														feed: editorTags,
														minimumCharacters: 1,
													},
												],
											},
										}}
									/>
								</label>
							</Form.Field>

							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<Button
									primary
									type="submit"
									disabled={!template.is_editable}
								>
									{t('common.submit')}
								</Button>
							</div>
						</Form>
					</Segment>
				</Grid.Column>
			</Grid>
		</Container>
	)
}
