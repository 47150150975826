import React from 'react'
import { Route, Switch } from 'react-router-dom'

// components
import Footer from '../../components/Footer'
import Menubar from '../../components/Menubar'

// pages
import ExamPage from './ExamPage'
import Exams from './Exams'
import TemplatePage from './TemplatePage'
import Templates from './Templates'
import NotFound from '../NotFound'

const appendLinks = [
	{
		route: '/solutions/do/templates',
		icon: 'plus square',
		label: 'templates',
	},
]

export default function DensitometryRoutes() {
	return (
		<Menubar appendLinks={appendLinks}>
			<Switch>
				<Route exact path="/solutions/do" component={Exams} />

				<Route
					exact
					path="/solutions/do/exams/:exam_id"
					component={ExamPage}
				/>

				<Route
					exact
					path="/solutions/do/templates"
					component={Templates}
				/>

				<Route
					exact
					path="/solutions/do/templates/:template_id"
					component={TemplatePage}
				/>

				<Route component={NotFound} />
			</Switch>

			<Footer />
		</Menubar>
	)
}
