import toast from 'react-hot-toast'

const messageConfig = {
	position: 'top-center',
	duration: 3000,
}

export const serverErrorMessage =
	'Erro no servidor. Tente novamente mais tarde.'

export function appendErrorMessages(response) {
	const messagesList = []
	Object.keys(response).forEach((fieldError) => {
		let messages = response[fieldError]
		messages = messages.isArray ? messages.join(' ') : messages
		messagesList.push(`${fieldError}: ${messages}`)
	})
	return messagesList
}

export function notifyError(message, config = {}) {
	toast.error(message, { ...messageConfig, ...config })
}

export function notifySuccess(message, config = {}) {
	toast.success(message, { ...messageConfig, ...config })
}

export function notifyWarning(message, config = {}) {
	toast.error(message, { ...messageConfig, ...config })
}

export function notifyInfo(message, config = {}) {
	toast(message, { ...messageConfig, ...config })
}
