import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Container, Grid, Icon } from 'semantic-ui-react'

// custom components
import Loading from '../../../components/Loading'
import SolutionTitle from '../../../components/SolutionTitle'
import TemplatesTable from './TemplatesTable'

// contexts
import { useSessionContext } from '../../../contexts/SessionContext'

// hooks
import useAuth from '../../../hooks/useAuth'
import usePageTitle from '../../../hooks/usePageTitle'

// utils
import HttpClient from '../../../services/HttpClient'

// styles
import './styles.scss'

export default function Templates() {
	const { session } = useSessionContext()
	const history = useHistory()
	const { t } = useTranslation()
	usePageTitle(t('template.templates'), 'templates')
	const [data, setData] = useState({
		count: 0,
		page_size: 0,
		next: '',
		previous: '',
		results: [],
	})
	const [loading, setLoading] = useState(true)

	useAuth()

	async function newTemplate() {
		const { data: newTemplateData } = await HttpClient.post(
			'do/templates/',
			{},
			session.csrf
		)
		history.push(`templates/${newTemplateData.id}`)
	}

	async function loadTemplates() {
		const { data: templatesData } = await HttpClient.get('do/templates')
		setData(templatesData)
		setLoading(false)
	}

	useEffect(() => {
		if (session.auth) {
			loadTemplates()
		}
		// eslint-disable-next-line
	}, [session.auth])

	if (loading) {
		return <Loading />
	}

	return (
		<Container style={{ minHeight: '70vh', marginTop: '100px' }}>
			<SolutionTitle
				title={t('delfos.densitometry')}
				to="/solutions/do"
			/>

			<Grid stackable columns="equal">
				<Grid.Column width={16}>
					<Button
						primary
						onClick={() => newTemplate()}
						floated="right"
					>
						<Icon name="plus" />
						{t('template.new_template')}
					</Button>

					<TemplatesTable data={data} />
				</Grid.Column>
			</Grid>
		</Container>
	)
}
