import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Dimmer,
	Icon,
	Label,
	Loader,
	Pagination,
	Segment,
	Table,
} from 'semantic-ui-react'

// contexts
import { useSessionContext } from '../../../contexts/SessionContext'

// utils
import { splitExamID } from '../../../utils/examID'
import { jobStatusColor } from '../../../utils/jobStatus'
import { isDarkTheme } from '../../../utils/theme'

export default function ExamsTable({ data, setPage, loading }) {
	const { theme } = useSessionContext()
	const { t } = useTranslation()

	return (
		<Segment attached inverted={isDarkTheme(theme)}>
			<Dimmer active={loading} inverted={!isDarkTheme(theme)}>
				<Loader size="large">{t('app.loading')}</Loader>
			</Dimmer>

			<Table size="large" striped inverted={isDarkTheme(theme)}>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>ID</Table.HeaderCell>
						<Table.HeaderCell>{t('exam.date')}</Table.HeaderCell>
						<Table.HeaderCell>{t('exam.status')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{data.results.map((exam) => (
						<Table.Row key={exam.id}>
							<Table.Cell className="uppercase">
								<Link to={`do/exams/${exam.id}`}>
									{splitExamID(exam.id)}
								</Link>
							</Table.Cell>

							<Table.Cell>{exam.created_at}</Table.Cell>

							<Table.Cell>
								{exam.job_status && (
									<Label
										color={jobStatusColor[exam.job_status]}
										className="uppercase"
									>
										{t(`exam.${exam.job_status}`)}
									</Label>
								)}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>

				<Table.Footer>
					<Table.Row>
						<Table.HeaderCell>
							{data.results.length} {t('densitometry.of')}{' '}
							{data.count} {t('exam.exams')}
						</Table.HeaderCell>

						<Table.HeaderCell />

						<Table.HeaderCell style={{ width: 0 }}>
							<Pagination
								boundaryRange={0}
								defaultActivePage={1}
								ellipsisItem={null}
								firstItem={null}
								inverted={isDarkTheme(theme)}
								lastItem={null}
								onPageChange={(e, { activePage }) =>
									setPage(activePage)
								}
								prevItem={{
									content: <Icon name="angle left" />,
									icon: true,
								}}
								nextItem={{
									content: <Icon name="angle right" />,
									icon: true,
								}}
								siblingRange={1}
								totalPages={Math.ceil(
									data.count / data.page_size
								)}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
		</Segment>
	)
}
