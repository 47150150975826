import { t } from 'i18next'
import { notifyWarning } from '../../utils/notify'

const FILES_LIMIT = 12
const MAX_UPLOAD_SIZE = 95000000 // 100mb

function isDicom(fileName) {
	return fileName.endsWith('.dcm')
}

function allowedUploadSize(files) {
	const totalSize = files.reduce(
		(partialSize, file) => partialSize + file.size,
		0
	)
	return totalSize < MAX_UPLOAD_SIZE
}

export function handleSelectFiles(files, setFilesUpload, inputFileRef) {
	if (!files.length) {
		setFilesUpload([])
		inputFileRef.current.value = null
		return
	}
	if (files.length > FILES_LIMIT) {
		notifyWarning(t('upload_images.limit_dcm_files'))
		return
	}
	const filesArray = Array.from(files)
	const validFiles = filesArray.filter((file) => isDicom(file.name))
	// console.log(validFiles)
	if (validFiles.length === files.length && allowedUploadSize(validFiles)) {
		setFilesUpload(validFiles)
		return
	}
	notifyWarning(t('upload_images.limit_dcm_size'))
	setFilesUpload([])
	inputFileRef.current.value = null
}

export function filesFormData(files) {
	const formData = new FormData()
	files.forEach((file) => {
		formData.append('files', file)
	})
	return formData
}
