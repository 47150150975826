import React from 'react'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import { Button, Icon } from 'semantic-ui-react'

// styles
import 'react-responsive-carousel/lib/styles/carousel.min.css'

export default function CarouselImages({ links }) {
	const { t } = useTranslation()

	async function openImage(alias, link) {
		const newWindow = window.open(
			'',
			'ExternalWindow',
			'toolbar=yes,scrollbars=yes,resizable=yes'
		)
		const bodyRegion = alias.split('_').pop()
		newWindow.document.body.innerHTML = `
            <title>${bodyRegion.toUpperCase()}</title>
            <img src="${link}" alt="Exam of ${bodyRegion}" />
        `
	}

	return (
		<Carousel>
			{Object.entries(links).map(([alias, link]) => (
				<div key={alias}>
					<Button
						icon
						size="mini"
						color="grey"
						title={t('densitometry.show_image')}
						onClick={() => openImage(alias, link)}
						style={{ position: 'absolute', left: 20, top: 20 }}
					>
						<Icon name="external alternate" fitted />
					</Button>

					<img src={link} alt={`Exam of ${alias.split('_').pop()}`} />

					{alias && (
						<p className="legend uppercase">
							{alias.split('_').pop()}
						</p>
					)}

					{/* { image.processed || <Icon circular inverted color="green" name="check" /> } */}
				</div>
			))}
		</Carousel>
	)
}
