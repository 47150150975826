import { t } from 'i18next'
import {
	notifyError,
	notifySuccess,
	notifyWarning,
} from '../../../utils/notify'

const FILES_LIMIT = 5
const MAX_UPLOAD_SIZE = 95000000 // 100mb
const SEPARATOR = '##### ----------------- #####'

function isValidFile(fileName) {
	return /\.dicom$|\.dcm$|\.jpeg$|\.jpg$|\.png$|\.webp$|\.pdf$/i.test(
		fileName
	)
}

function allowedUploadSize(files) {
	const totalSize = files.reduce(
		(partialSize, file) => partialSize + file.size,
		0
	)
	return totalSize < MAX_UPLOAD_SIZE
}

export function handleSelectFiles(files, setFilesUpload, inputFileRef) {
	if (!files.length) {
		setFilesUpload([])
		inputFileRef.current.value = null
		return
	}
	if (files.length > FILES_LIMIT) {
		notifyWarning(t('upload_images.limit_img_files'))
		return
	}
	const filesArray = Array.from(files)
	const validFiles = filesArray.filter((file) => isValidFile(file.name))
	// console.log(validFiles)
	if (validFiles.length === files.length && allowedUploadSize(validFiles)) {
		setFilesUpload(validFiles)
		return
	}
	notifyWarning(t('upload_images.limit_img_size'))
	setFilesUpload([])
	inputFileRef.current.value = null
}

export function filesFormData(files) {
	const formData = new FormData()
	files.forEach((file) => {
		formData.append('files', file)
	})
	return formData
}

export function copyToClipboard(elementID) {
	const styledText = document.getElementById(elementID).innerHTML
	function listener(e) {
		e.clipboardData.setData('text/html', styledText)
		e.clipboardData.setData('text/plain', styledText)
		e.preventDefault()
	}
	try {
		document.addEventListener('copy', listener)
		document.execCommand('copy')
		document.removeEventListener('copy', listener)
		// 	navigator.clipboard.writeText(text)
		notifySuccess(t('densitometry.success_copy_text'))
	} catch {
		notifyError(t('densitometry.error_copy_text'))
	}
}

export function splitReportMask(text) {
	const splitted = text.replace(/\n/g,'').split(SEPARATOR)
	return splitted

}
