import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Button,
	Container,
	Form,
	Grid,
	Icon,
	Segment,
	Tab,
} from 'semantic-ui-react'

// custom components
import CarouselImages from './CarouselImages'
import DragDropFile from './DragDropFile'
import Loading from '../../../components/Loading'
import SubmitLoading from './SubmitLoading'
import SolutionTitle from '../../../components/SolutionTitle'

// contexts
import { useSessionContext } from '../../../contexts/SessionContext'

// hooks
import useAuth from '../../../hooks/useAuth'
import usePageTitle from '../../../hooks/usePageTitle'

// utils
import HttpClient from '../../../services/HttpClient'
import { copyToClipboard, filesFormData, handleSelectFiles, splitReportMask } from './methods'
import { splitExamID } from '../../../utils/examID'
import { notifySuccess, notifyWarning } from '../../../utils/notify'

// styles
import './styles.scss'

const CHECK_INTERVAL = 10000

function ReferenceText() {
	return (
		<p style={{ fontSize: '9pt' }}>
			<b>T-SCORE</b>
			<br />
			Laudos densitométricos na pós-menopausa e homens de idade igual ou
			superior a 50 anos: A classificação da OMS é aplicável e utiliza-se
			a metodologia <b>T-SCORE</b> com a seguinte classificação:
			<br />
			Normal............................................T-SCORE..................de
			+0,0 a -1,0 DP <br />
			Osteopenia....................................T-SCORE..................de
			-1,1 a -2,4 DP <br />
			Osteoporose Densitométrica.....T-SCORE .................a partir de
			-2,5 DP <br />
			Osteoporose Estabelecida..........T-SCORE .................a partir
			de -2,5 DP associado a fraturas. <br />
			<br />
			<br />
			<b>Z-SCORE</b>
			<br />
			Em laudos densitométricos nas mulheres na pré-menopausa, homens com
			idade abaixo dos 50 anos e crianças/adolescentes com idade inferior
			a 20 anos aplica-se a metodologia <b>Z-SCORE</b>. <br />
			a) Z-SCORE de +0,0 até -1,9 significa &quot;densidade óssea DENTRO
			dos limites esperados para a idade&quot; <br />
			b) Z-SCORE a partir de -2,0 significa &quot;densidade óssea ABAIXO
			da faixa esperada para a idade&quot;
		</p>
	)
}

function TabExamResult({ t, tScore, zScore }) {
	const panes = [
		{
			menuItem: t('densitometry.result_t_score'),
			render: () => (
				<Tab.Pane>
					{tScore ? (
						<>
							<Button
								icon
								size="mini"
								title={t('densitometry.copy_to_clipboard')}
								onClick={() =>
									copyToClipboard('result-t-score')
								}
								style={{ position: 'absolute', right: 10 }}
							>
								<Icon name="copy" />
							</Button>

							<div
								id="result-t-score"
								dangerouslySetInnerHTML={{ __html: tScore }}
							/>
						</>
					) : (
						<div>{t('densitometry.no_results')}</div>
					)}
				</Tab.Pane>
			),
		},
		{
			menuItem: t('densitometry.result_z_score'),
			render: () => (
				<Tab.Pane>
					{zScore ? (
						<>
							<Button
								icon
								size="mini"
								title={t('densitometry.copy_to_clipboard')}
								onClick={() =>
									copyToClipboard('result-z-score')
								}
								style={{ position: 'absolute', right: 10 }}
							>
								<Icon name="copy" />
							</Button>

							<div
								id="result-z-score"
								dangerouslySetInnerHTML={{ __html: zScore }}
							/>
						</>
					) : (
						<div>{t('densitometry.no_results')}</div>
					)}
				</Tab.Pane>
			),
		},
		{
			menuItem: t('densitometry.reference'),
			render: () => (
				<Tab.Pane>
					<ReferenceText />
				</Tab.Pane>
			),
		},
	]

	return (
		<Tab
			className="tab-container"
			panes={panes}
			style={{ marginBottom: 10 }}
		/>
	)
}

export default function ExamPage() {
	const { exam_id } = useParams()
	const { session } = useSessionContext()
	const { t } = useTranslation()
	usePageTitle(`${t('exam.exam')} ${splitExamID(exam_id)}`, 'exam')
	const history = useHistory()

	const [checkExam, setCheckExam] = useState(false)
	const [exam, setExam] = useState({
		template: null,
		job_status: 'processing',
		links: {},
	})
	const [filesUpload, setFilesUpload] = useState([])
	const [formLoading, setFormLoading] = useState(false)
	const [loading, setLoading] = useState(true)
	const [template, setTemplate] = useState(null)
	const [resultType, setResultType] = useState('main')
	const [templateOptions, setTemplateOptions] = useState([])
	const [tScoreMaskHTML, setTScoreMaskHTML] = useState(null)
	const [zScoreMaskHTML, setZScoreMaskHTML] = useState(null)
	const inputFileRef = useRef(null)

	useAuth()

	async function loadExam() {
		const attrsURL = encodeURIComponent('["age", "sex"]')
		const { data } = await HttpClient.get(
			`do/exams/${exam_id}?full=true&links=true&attrs=${attrsURL}`
		)
		// console.log(data)
		if (data.links.result) {
			const report_mask = await fetch(data.links.result.report_mask)
			const text = await report_mask.text()
			const [tScore, zScore] = splitReportMask(text)
			setTScoreMaskHTML(tScore)
			setZScoreMaskHTML(zScore)
		} else {
			setTScoreMaskHTML(t('exam.report_not_found'))
		}

		setExam(data)
		setLoading(false)
		if (data.job_status === 'finished') {
			setCheckExam(false)
			setFormLoading(false)
		} else if (data.job_status === 'processing') {
			setCheckExam(true)
		} else if (data.job_status === 'error') {
			notifyWarning(t('notification.exam_error'))
			history.push('/solutions/do')
		}
	}

	async function loadTemplates() {
		const { data } = await HttpClient.get('do/templates?is_active=1')
		setTemplateOptions(
			data.results.map((templateData) => ({
				key: templateData.id,
				text: templateData.name,
				value: templateData.id,
			}))
		)
	}

	function validateForm() {
		return filesUpload.length && template
	}

	function handleClearForm() {
		setTemplate(null)
		handleSelectFiles([], setFilesUpload, inputFileRef)
	}

	async function handleSubmit(event) {
		event.preventDefault()
		setFormLoading(true)
		const formData = filesFormData(filesUpload)
		formData.append('template', template)
		formData.append('result_type', resultType)
		const { data, message } = await HttpClient.patchFormData(
			`do/exams/${exam_id}/upload_images`,
			formData,
			session.csrf
		)
		try {
			if (data) {
				notifySuccess(message)
				setExam(data)
				setCheckExam(true)
			} else {
				history.push('/solutions/do')
				notifyWarning(message)
			}
		} catch (err) {
			// console.log(err)
			notifyWarning(t('notification.error_upload'))
		}
	}

	useEffect(() => {
		if (session.auth) {
			loadExam()
			loadTemplates()
		}

		// eslint-disable-next-line
	}, [session.auth])

	useEffect(() => {
		if (checkExam) {
			const interval = setInterval(async () => {
				loadExam()
			}, CHECK_INTERVAL)
			return () => clearInterval(interval)
		}
		return null
		// eslint-disable-next-line
	}, [checkExam])

	if (loading) {
		return <Loading />
	}

	if (exam.job_status === 'processing' && !formLoading) {
		return <Loading />
	}

	return (
		<Container style={{ marginTop: '100px' }}>
			<SolutionTitle
				title={t('delfos.densitometry')}
				to="/solutions/do"
			/>

			<Grid divided stackable columns={2} style={{ minHeight: '80vh' }}>
				{exam.job_status === 'finished' ? (
					<Grid.Row stretched className="inverted-mobile">
						<Grid.Column>
							<CarouselImages links={exam.links.orig} />
						</Grid.Column>

						<Grid.Column>
							<TabExamResult
								t={t}
								tScore={tScoreMaskHTML}
								zScore={zScoreMaskHTML}
							/>

							<Tab
								className="tab-container"
								panes={[
									{
										menuItem: t(
											'densitometry.additional_info'
										),
										render: () => (
											<Tab.Pane>
												{Object.entries(
													exam.additional_info
												).map(([key, value]) => (
													<p key={key}>
														<strong>{key.toUpperCase()}:</strong>{' '}
														{value}
													</p>
												))}
											</Tab.Pane>
										),
									},
								]}
							/>
						</Grid.Column>
					</Grid.Row>
				) : (
					<Form
						className="row"
						loading={formLoading}
						onSubmit={(event) => handleSubmit(event)}
					>
						<Grid.Column>
							<DragDropFile
								inputFileRef={inputFileRef}
								filesUpload={filesUpload}
								setFilesUpload={setFilesUpload}
							/>
						</Grid.Column>

						<Grid.Column>
							<Segment>
								<div className="field">
									<label htmlFor="examID">
										ID
										<input
											id="examID"
											value={splitExamID(exam_id)}
											disabled
										/>
									</label>
								</div>

								<Form.Select
									fluid
									label="Template"
									options={templateOptions}
									placeholder={t(
										'densitometry.select_template'
									)}
									value={template}
									onChange={(event, data) =>
										setTemplate(data.value)
									}
									required
								/>

								<Form.Select
									fluid
									label={t('densitometry.exclude_vertebra')}
									options={[
										{
											key: 'main',
											text: t('common.no'),
											value: 'main',
										},
										{
											key: 'best',
											text: t('common.yes'),
											value: 'best',
										},
									]}
									value={resultType}
									onChange={(event, data) =>
										setResultType(data.value)
									}
									required
								/>

								<div
									className="field"
									style={{
										display: 'flex',
										justifyContent: 'end',
									}}
								>
									<Button.Group>
										<Form.Button
											primary
											disabled={!validateForm()}
										>
											{t('common.submit')}
										</Form.Button>

										<Button.Or text={t('common.or')} />

										<Form.Button
											type="reset"
											onClick={() => handleClearForm()}
										>
											{t('common.cancel')}
										</Form.Button>
									</Button.Group>
								</div>
							</Segment>
						</Grid.Column>

						{formLoading && <SubmitLoading />}
					</Form>
				)}
			</Grid>
		</Container>
	)
}
