import React, { useEffect, useRef } from 'react'
import './styles.scss'

export default function SubmitLoading() {
	const text1 = useRef(null)
	const text2 = useRef(null)

	useEffect(() => {
		const texts = [
			'Uploading...',
			'Loading images...',
			'Extracting info...',
			'Processing...',
			'Merging to template...',
			'Finishing...',
		]

		const morphTime = 1.5
		const cooldownTime = 3.0

		let textIndex = -1
		let time = new Date()
		let morph = 0
		let cooldown = cooldownTime

		text1.current.textContent = texts[textIndex % texts.length]
		text2.current.textContent = texts[(textIndex + 1) % texts.length]

		function setMorph(fraction) {
			if (text1.current && text2.current) {
				text2.current.style.filter = `blur(${Math.min(
					8 / fraction - 8,
					100
				)}px)`
				text2.current.style.opacity = `${fraction ** 0.4 * 100}%`

				const newfraction = 1 - fraction
				text1.current.style.filter = `blur(${Math.min(
					8 / newfraction - 8,
					100
				)}px)`
				text1.current.style.opacity = `${newfraction ** 0.4 * 100}%`

				text1.current.textContent = texts[textIndex % texts.length]
				text2.current.textContent =
					texts[(textIndex + 1) % texts.length]
			}
		}

		function doMorph() {
			morph -= cooldown
			cooldown = 0
			let fraction = morph / morphTime
			if (fraction > 1) {
				cooldown = cooldownTime
				fraction = 1
			}
			setMorph(fraction)
		}

		function doCooldown() {
			if (text1.current && text2.current) {
				morph = 0
				text2.current.style.filter = ''
				text2.current.style.opacity = '100%'
				text1.current.style.filter = ''
				text1.current.style.opacity = '0%'
			}
		}

		function animate() {
			requestAnimationFrame(animate)
			const newTime = new Date()
			const shouldIncrementIndex = cooldown > 0
			const dt = (newTime - time) / 1000
			time = newTime
			cooldown -= dt

			if (textIndex < texts.length - 1) {
				if (cooldown <= 0) {
					if (shouldIncrementIndex) {
						textIndex += 1
					}
					doMorph()
				} else {
					doCooldown()
				}
			}
		}

		animate()
	}, [])

	return (
		<div className="submit-loading">
			<div id="container">
				<img
					src="/images/magnifier.svg"
					alt="Magnifier icon"
					width={120}
				/>
				<span id="text1" ref={text1} />
				<span id="text2" ref={text2} />
			</div>

			<svg id="filters">
				<defs>
					<filter id="threshold">
						<feColorMatrix
							in="SourceGraphic"
							type="matrix"
							values="1 0 0 0 0
									0 1 0 0 0
									0 0 1 0 0
									0 0 0 255 -140"
						/>
					</filter>
				</defs>
			</svg>
		</div>
	)
}
