import { notifyError, notifySuccess } from '../../utils/notify'

export function calculateZoom(height) {
	return (-0.5 * height) / window.innerHeight
}

export function copyToClipboard(text) {
	try {
		navigator.clipboard.writeText(text)
		notifySuccess('Text copied to clipboard')
	} catch {
		notifyError('Error to copy text')
	}
}

export async function downloadImage(imageSrc, exam_id = '') {
	const image = await fetch(imageSrc)
	const imageBlog = await image.blob()
	const imageURL = URL.createObjectURL(imageBlog)
	const link = document.createElement('a')
	link.href = imageURL
	link.download = `${exam_id}_report.png`
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}
