export const REDIRECT_STATE = { redirect: true }

export function fromRedirect(routeParams) {
	const state = routeParams.state || {}
	return state.redirect
}

export function cleanLocationState(history) {
	history.replace(history.location.pathname, {})
}
