const API_ROUTE = process.env.REACT_APP_ROUTE_API

const HttpClient = {
	delete: async (route, token = '') =>
		fetch(API_ROUTE + route, {
			credentials: 'include',
			method: 'DELETE',
			headers: new Headers({
				'X-CSRFToken': token,
			}),
		})
			.then((response) => {
				if (response.status === 401) window.location.replace('/login')
				else if (response.status < 500) {
					return response.json().then((data) => ({
						...data,
						ok: response.ok,
						status: response.status,
					}))
				}
				return false
			})
			.catch((err) => console.error(err)),
	get: async (route) =>
		fetch(API_ROUTE + route, { credentials: 'include' })
			.then((response) => {
				if (response.status === 401) window.location.replace('/login')
				else if (response.status === 404) window.location.replace('/')
				else if (response.status < 500) {
					return response.json().then((data) => ({
						...data,
						ok: response.ok,
						status: response.status,
					}))
				}
				return false
			})
			.catch((err) => console.error(err)),
	getSession: async () =>
		fetch(`${API_ROUTE}session/`, { credentials: 'include' })
			.then((response) => {
				if (response.status === 401) window.location.replace('/login')
				else if (response.status < 500) {
					const token = response.headers.get('x-csrftoken')
					return {
						data: response.json(),
						token,
						ok: response.ok,
						status: response.status,
					}
				}
				return false
			})
			.catch((err) => console.error(err)),
	patch: async (route, data, token = '') =>
		fetch(API_ROUTE + route, {
			credentials: 'include',
			method: 'PATCH',
			body: JSON.stringify(data),
			headers: new Headers({
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRFToken': token,
			}),
		})
			.then((response) => {
				if (response.status === 401) window.location.replace('/login')
				else if (response.status < 500) {
					return response.json().then((respData) => ({
						...respData,
						ok: response.ok,
						status: response.status,
					}))
				}
				return false
			})
			.catch((err) => console.error(err)),
	patchFormData: async (route, formData, token = '') =>
		fetch(API_ROUTE + route, {
			credentials: 'include',
			method: 'PATCH',
			body: formData,
			headers: new Headers({
				'X-CSRFToken': token,
			}),
		})
			.then((response) => {
				if (response.status === 401) window.location.replace('/login')
				else if (response.status < 500) {
					return response.json().then((data) => ({
						...data,
						ok: response.ok,
						status: response.status,
					}))
				}
				return false
			})
			.catch((err) => console.error(err)),
	post: async (route, data, token = '') =>
		fetch(API_ROUTE + route, {
			credentials: 'include',
			method: 'POST',
			body: JSON.stringify(data),
			headers: new Headers({
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRFToken': token,
			}),
		})
			.then((response) => {
				if (response.status === 401) window.location.replace('/login')
				else if (response.status < 500) {
					return response.json().then((respData) => ({
						...respData,
						ok: response.ok,
						status: response.status,
					}))
				}
				return false
			})
			.catch((err) => console.error(err)),
	postFormData: async (route, formData, token = '') =>
		fetch(API_ROUTE + route, {
			credentials: 'include',
			method: 'POST',
			body: formData,
			headers: new Headers({
				'X-CSRFToken': token,
			}),
		})
			.then((response) => {
				if (response.status === 401) window.location.replace('/login')
				else if (response.status < 500) {
					return response.json().then((data) => ({
						...data,
						ok: response.ok,
						status: response.status,
					}))
				}
				return false
			})
			.catch((err) => console.error(err)),
	put: async (route, data, token = '') =>
		fetch(API_ROUTE + route, {
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify(data),
			headers: new Headers({
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-CSRFToken': token,
			}),
		})
			.then((response) => {
				if (response.status === 401) window.location.replace('/login')
				else if (response.status < 500) {
					return response.json().then((respData) => ({
						...respData,
						ok: response.ok,
						status: response.status,
					}))
				}
				return false
			})
			.catch((err) => console.error(err)),
	putFormData: async (route, formData, token = '') =>
		fetch(API_ROUTE + route, {
			credentials: 'include',
			method: 'PUT',
			body: formData,
			headers: new Headers({
				'X-CSRFToken': token,
			}),
		})
			.then((response) => {
				if (response.status === 401) window.location.replace('/login')
				else if (response.status < 500) {
					return response.json().then((data) => ({
						...data,
						ok: response.ok,
						status: response.status,
					}))
				}
				return false
			})
			.catch((err) => console.error(err)),
}

export default HttpClient
