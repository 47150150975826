export const themes = {
	'dark-theme': {
		color: '#3A3A3A',
		icon: '🌛',
	},
	'light-theme': {
		color: '#DDDDDD',
		icon: '🌞',
	},
}

export function getTheme() {
	return localStorage.getItem('theme') || 'light-theme'
}

export function saveTheme(value) {
	localStorage.setItem('theme', value)
}

export function isDarkTheme(theme = '') {
	return (theme || getTheme()) === 'dark-theme'
}

export function toggleTheme() {
	const currentTheme = isDarkTheme() ? 'light-theme' : 'dark-theme'
	saveTheme(currentTheme)
	return currentTheme
}

export const GRAY = () => themes[getTheme()].color
