import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import cx from 'classnames'

// custom components
import DefaultBackground from '../../components/DefaultBackground'
import LoginFooter from '../../components/LoginFooter'

// custom hooks
import usePageTitle from '../../hooks/usePageTitle'
import { useSessionContext } from '../../contexts/SessionContext'

// utils
import HttpClient from '../../services/HttpClient'
import {
	notifyError,
	notifySuccess,
	notifyWarning,
	serverErrorMessage,
} from '../../utils/notify'
import { validatePassword } from '../../utils/validation'

export default function ResetPassword() {
	const { t } = useTranslation()
	usePageTitle(t('login.reset_password'), 'login')
	const history = useHistory()
	const { session } = useSessionContext()
	const [code, setCode] = useState('')
	const [confPassword, setConfPassword] = useState('')
	const [password, setPassword] = useState('')
	const [isPassword, setIsPassword] = useState(true)
	// const [valid, setValid] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)
		// console.log(urlParams.has("key"))
		if (urlParams.has('key')) {
			HttpClient.get(`reset/?key=${urlParams.get('key')}`)
				.then((response) => {
					if (response.data.valid) {
						console.log('Valid!!')
						// setValid(true)
					} else {
						history.push('/login')
						// setValid(false)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		} else {
			history.push('/login')
			// setValid(false)
		}
		// eslint-disable-next-line
	}, [])

	async function handleSubmit(e) {
		e.preventDefault()
		setButtonLoading(true)
		const formData = {
			code,
			password,
		}

		if (validatePassword(password, confPassword)) {
			const urlParams = new URLSearchParams(window.location.search)
			const response = await HttpClient.post(
				`reset/?key=${urlParams.get('key')}`,
				formData,
				session.csrf
			)
			if (response) {
				if (response.data) {
					notifySuccess(response.message)
				} else {
					notifyWarning(response.message)
				}
				history.push('/login')
			} else {
				notifyError(serverErrorMessage)
			}
		}
		setButtonLoading(false)
	}

	function validateForm() {
		return code && password && confPassword
	}

	return (
		<DefaultBackground>
			<Grid textAlign="center" verticalAlign="middle">
				<Grid.Column>
					<Header as="h1" textAlign="center" inverted>
						{t('login.reset_password')}
					</Header>

					<Form size="large" onSubmit={handleSubmit}>
						<Segment>
							<Form.Input
								fluid
								icon="signup"
								iconPosition="left"
								name="code"
								type="number"
								placeholder={t('login.code')}
								onChange={(e, { value }) => setCode(value)}
							/>

							<Form.Input
								fluid
								iconPosition="left"
								name="password"
								placeholder={t('login.password')}
								type={isPassword ? 'password' : 'text'}
								onChange={(e, { value }) =>
									setPassword(value.trim())
								}
								value={password}
							>
								<Icon name="lock" />
								<input />
								<Icon
									className="link"
									name={cx({
										eye: true,
										slash: isPassword,
									})}
									onClick={() => setIsPassword(!isPassword)}
								/>
							</Form.Input>

							<Form.Input
								fluid
								iconPosition="left"
								name="confPassword"
								placeholder={t('login.confirm_password')}
								type={isPassword ? 'password' : 'text'}
								onChange={(e, { value }) =>
									setConfPassword(value.trim())
								}
								value={confPassword}
							>
								<Icon name="lock" />
								<input />
								<Icon
									className="link"
									name={cx({
										eye: true,
										slash: isPassword,
									})}
									onClick={() => setIsPassword(!isPassword)}
								/>
							</Form.Input>

							<Button
								className="blue-harpia"
								fluid
								size="large"
								loading={buttonLoading}
								disabled={!validateForm() || buttonLoading}
							>
								{t('login.reset')}
							</Button>
						</Segment>
					</Form>
				</Grid.Column>
			</Grid>

			<LoginFooter />
		</DefaultBackground>
	)
}
