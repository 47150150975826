import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Header, Icon } from 'semantic-ui-react'
import { handleSelectFiles } from './methods'

export default function DragDropFile({
	inputFileRef,
	filesUpload,
	setFilesUpload,
}) {
	const { t } = useTranslation()

	return (
		<Form.Field className="file-input-container">
			<input
				multiple
				accept=".dcm, .dicom, image/dcm, image/png, image/jpeg, image/jpg, application/pdf"
				className="files-input"
				name="files"
				onChange={(event) =>
					handleSelectFiles(
						event.target.files,
						setFilesUpload,
						inputFileRef
					)
				}
				ref={inputFileRef}
				type="file"
			/>

			<div className="content">
				{filesUpload.length ? (
					<Header as="h3" icon>
						<Icon name="images" />
						<Header.Subheader>
							{filesUpload.length}{' '}
							{t('upload_images.selected_files')}
						</Header.Subheader>
					</Header>
				) : (
					<Header as="h3" icon>
						<Icon name="images outline" />
						{t('densitometry.drag_and_drop')}
						<Header.Subheader>
							{t('densitometry.click_here')}
						</Header.Subheader>
					</Header>
				)}
			</div>
		</Form.Field>
	)
}
